import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, PrimaryClaim, SecondaryCallout, KMCurve, ComparisonTable, EndpointDisplay, FeaturedResource } from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import TNBC from '../../components/StudyDesign/__study-design/tnbc'
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-355 - Clinical Trial Results | HCP`,
    keywords: `keynote 355, advanced tnbc clinical trial results`,
    description: `Health care professionals may view findings from KEYNOTE-355, a clinical trial for patients with locally recurrent unresectable or metastatic triple-negative breast cancer (TNBC).`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/triple-negative-breast-cancer/","@type":"ApprovedIndication","name":"KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA, in combination with chemotherapy, is indicated for the treatment of patients with locally recurrent unresectable or metastatic triple-negative breast cancer (TNBC) whose tumors express programmed death ligand 1 (PD-L1) [combined positive score (CPS) ≥10] as determined by an FDA-approved test."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/triple-negative-breast-cancer/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/triple-negative-breast-cancer/","@type":"MedicalTrial","name":"KEYNOTE-355","description":"KEYTRUDA studied in combination with paclitaxel, nab-paclitaxel, or gem carbo for the treatment of advanced TNBC (PD-L1 CPS ≥10)","trialDesign":"KEYTRUDA was studied with 3 different chemotherapy regimens (paclitaxel, nab-paclitaxel, or gemcitabine and carboplatin) in a multicenter, randomized, double-blind, placebo-controlled trial in 847 patients with advanced TNBC."}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 26;
const jobCode = jobCodes[26].jobCode;


const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];


const crossLinkContent = {
    sections: [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: 'High-Risk Early-Stage TNBC', url: '/efficacy/early-stage-triple-negative-breast-cancer' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/triple-negative-breast-cancer/' },
                { text: 'Dosing', url: '/dosing/options/' },
                { text: 'PD&#8288;-&#8288;L1 Testing & Scoring', url: '/biomarker-testing/pd-l1/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;355' }, 
    { label: 'Study Design from KEYNOTE&#8288;-&#8288;355' }, 
]

const DORtableFootnotes = [
    {
        label: "b.",
        text: "Based on the pre-specified final analysis."
    },
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                    <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">KEYTRUDA studied in combination with paclitaxel, nab-paclitaxel, or gem carbo for the treatment of advanced TNBC (PD&#8288;-&#8288;L1 CPS ≥10)</PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>
                            <SecondaryCallout
                                title="In KEYNOTE&#8288;-&#8288;355, 38% (323/847) of patients with advanced TNBC had tumors that expressed PD&#8288;-&#8288;L1 with a CPS ≥10"
                            />
                            <KMCurve 
                                title="Kaplan-Meier Estimates for Overall Survival (OS) in KEYNOTE&#8288;-&#8288;355 (PD&#8288;-&#8288;L1 CPS ≥10)" 
                                hr="HR=0.73; 95% CI, 0.55–0.95; <i>P</i>=0.0093<sup>a</sup>" 
                                image={() => <StaticImage src="../../assets/bre-km-os-v-1.png" placeholder="blurred" alt="Kaplan-Meier Estimates of OS in KEYNOTE-355 (PD-L1 CPS ≥10)" />} 
                            />
                            <SecondaryCallout 
                                title="27% reduced risk of death shown with KEYTRUDA in combination with paclitaxel, nab-paclitaxel, or gem carbo vs paclitaxel, nab-paclitaxel, or gem carbo alone in patients with advanced TNBC whose tumors expressed PD-L1 with a CPS ≥10"
                                text="HR=0.73; 95% CI, 0.55–0.95; <i>P</i>=0.0093<sup>a</sup>"
                                footnotes={[
                                    {
                                        label: 'a.',
                                        text: 'HR based on stratified Cox regression model. One-sided <i>P</i>-value based on stratified log-rank test (compared to a significance level of 0.0113).',
                                    }
                                ]}
                                definitions={'CI = confidence interval; gem carbo = gemcitabine and carboplatin; HR = hazard ratio; nab-paclitaxel = paclitaxel protein⁠-⁠bound; Q3W = every 3 weeks.'}
                            />
                            <ComparisonTable
                                title="Overall Survival (OS)<sup>b</sup>"
                                footnotes={[
                                    {
                                        label: 'b.',
                                        text: 'Based on the pre-specified final analysis.'
                                    }
                                ]}
                                data={
                                [
                                    [
                                        {
                                            legendTitle: 'KEYTRUDA + paclitaxel, nab-paclitaxel, or gem carbo (n=220)',
                                        },
                                        {
                                            legendTitle: 'Placebo + paclitaxel, nab-paclitaxel, or gem carbo (n=103)',
                                        },

                                    ],
                                    [
                                        {
                                            label: 'Events Observed',
                                            dataPoint: '155/220 (70%)',
                                        },
                                        {
                                            label: 'Events Observed',
                                            dataPoint: '84/103 (82%)',
                                        },
                                    ],
                                    [
                                        {
                                            label: 'Median OS',
                                            dataPoint: '23.0 Months',
                                            dataSubPoint: '(95% CI, 19.0–26.3)'
                                        },
                                        {
                                            label: 'Median OS',
                                            dataPoint: '16.1 Months',
                                            dataSubPoint: '(95% CI, 12.6–18.8)'
                                        },
                                    ],
                                ]
                            } />
                            <KMCurve 
                                title="Kaplan-Meier Estimates for Progression-Free Survival (PFS) in KEYNOTE&#8288;-&#8288;355 (PD&#8288;-&#8288;L1 CPS ≥10)" 
                                hr="HR=0.65; 95% CI, 0.49–0.86; <i>P</i>=0.0012<sup>c</sup>" 
                                image={() => <StaticImage src="../../assets/bre-km-pfs-v-2.png" placeholder="blurred" alt="Kaplan-Meier Estimates of PFS in KEYNOTE-355 (PD-L1 CPS ≥10)" />} 
                            />
                            <SecondaryCallout 
                                title="35% reduction in the risk of disease progression or death shown with KEYTRUDA in combination with paclitaxel, nab-paclitaxel, or gem carbo vs paclitaxel, nab-paclitaxel, or gem carbo alone in patients with tumors that expressed PD&#8288;-&#8288;L1 with a CPS ≥10" text="HR=0.65; 95% CI, 0.49–0.86; <i>P</i>=0.0012<sup>c</sup>" 
                                alt=""
                                footnotes={[
                                    {
                                        label: 'c',
                                        text: 'HR based on stratified Cox regression model. One-sided <i>P</i>-value based on stratified log-rank test (compared to a significance level of 0.00411).'
                                    }
                                ]}
                            />
                            <ComparisonTable
                                title="Progression-Free Survival (PFS)<sup>d</sup>"
                                footnotes={[
                                    {
                                        label: 'd.',
                                        text: 'Based on a pre-specified interim analysis.'
                                    }
                                ]}
                                data={
                                [
                                    [
                                        {
                                            legendTitle: 'KEYTRUDA + paclitaxel, nab-paclitaxel, or gem carbo (n=220)',
                                        },
                                        {
                                            legendTitle: 'Placebo + paclitaxel, nab-paclitaxel, or gem carbo (n=103)',
                                        },

                                    ],
                                    [
                                        {
                                            label: 'Events Observed',
                                            dataPoint: '136/220 (62%)',
                                        },
                                        {
                                            label: 'Events Observed',
                                            dataPoint: '79/103 (77%)',
                                        },
                                    ],
                                    [
                                        {
                                            label: 'Median PFS',
                                            dataPoint: '9.7 Months',
                                            dataSubPoint: '(95% CI, 7.6–11.3)'
                                        },
                                        {
                                            label: 'Median PFS',
                                            dataPoint: '5.6 Months',
                                            dataSubPoint: '(95% CI, 5.3–7.5)'
                                        },
                                    ],
                                ]
                            } />


                            <EndpointDisplay title="Objective Response Rate (ORR)<sup>b</sup>" limitation="In 220 patients treated with KEYTRUDA + paclitaxel, nab-paclitaxel, or gem carbo vs 103 patients treated with placebo + paclitaxel, nab-paclitaxel, or gem carbo" cards={
                                [
                                    {
                                        dataType: 'ORR',
                                        label: 'KEYTRUDA + paclitaxel, nab-paclitaxel, or gem carbo',
                                        rate: '53%',
                                        ci: '95% CI, 46–59',
                                        n: '116/220',
                                        completeResponseRate: '17%',
                                        partialResponseRate: '35%',
                                    },
                                    
                                ]
                            }
                            />
                            <EndpointDisplay
                                cards={
                                    [
                                        {
                                            dataType: 'ORR',
                                            label: 'Placebo + paclitaxel, nab-paclitaxel, or gem carbo',
                                            rate: '41%',
                                            ci: '95% CI, 31–51',
                                            n: '42/103',
                                            completeResponseRate: '14%',
                                            partialResponseRate: '27%',
                                        }
                                    ]
                                }
                            />

                            <ComparisonTable title="Duration of Response (DOR)<sup>b</sup>" footnotes={DORtableFootnotes} data={
                                [
                                    [
                                        {
                                            legendTitle: 'KEYTRUDA + paclitaxel, nab-paclitaxel, or gem carbo (n=116)',
                                        },
                                        {
                                            legendTitle: 'Placebo + paclitaxel, nab-paclitaxel, or gem carbo (n=42)',
                                        },

                                    ],
                                    [
                                        {
                                            label: 'Median DOR',
                                            dataPoint: '12.8 Months',
                                            dataSubPoint: '(95% CI, 9.9–25.9)'
                                        },
                                        {
                                            label: 'Median DOR',
                                            dataPoint: '7.3 Months',
                                            dataSubPoint: '(95% CI, 5.5–15.4)'
                                        }
                                    ],
                                ]
                            } />
                        </PageSection>

                        <PageSection title={anchorLinkModelData[1].label}>
                            <TNBC />
                        </PageSection>
                    </TemplateColumn>
                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
